<template>
  <div>sign Up</div>
</template>

<script>
export default {
  name: "SignUp"
};
</script>
<style lang="scss"></style>
